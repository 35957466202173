<template>
  <b-modal
    :visible="valueVisible"
    id="modal-popup"
    hide-footer
    modal-class="modal-popup"
  >
    <template #modal-header="{}">
      <h5>{{ title }}</h5>
      <b-button
        size="sm"
        style="background-color: white; border-color: white"
        class="close"
        @click="closeModal()"
      >
        ✖️
      </b-button>
    </template>
    <div class="page-list-content">
      <div class="card-img" v-html="content"></div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListPopUp",
  components: {},
  data() {
    return {
      dataPopup: {
        shop_id: "",
        popup_send: "",
      },
      content: "",
      indexData: 0,
      isHideModal: false,
      popup_tranmission: null,
      valueVisible: false,
      dataRequest: {
        shop_id: "",
        user_id: "",
        popup_id: "",
      },
      title: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    this.popup_tranmission = parseInt(localStorage.getItem("popupSend"));
    localStorage.setItem("popupSend", 0);
    this.getlistTimingPopUp();
  },
  computed: {
    ...mapGetters(["listPopUp", "message", "success", "error"]),
  },
  watch: {
    listPopUp() {
      if (this.listPopUp.data.length > 0) {
        this.valueVisible = true;
        this.content = this.listPopUp.data[this.indexData].popup_content;
        this.title = this.listPopUp.data[this.indexData].popup_title;
      } else {
        this.valueVisible = false;
        this.$emit("show_modal", this.isHideModal);
      }
    },
  },
  methods: {
    ...mapActions({ getlistTimingPopUpUser: "getlistTimingPopUpUser" }),
    ...mapActions({ CancelPopup: "CancelPopup" }),
    getlistTimingPopUp() {
      this.dataPopup.shop_id = this.shop_id;
      this.dataPopup.popup_send = this.popup_tranmission;
      this.getlistTimingPopUpUser(this.dataPopup);
    },
    closeModal() {
      if (this.indexData + 1 !== this.listPopUp.data.length) {
        this.indexData = this.indexData + 1;
        this.content = this.listPopUp.data[this.indexData].popup_content;
        this.dataRequest.shop_id = this.shop_id;
        this.dataRequest.user_id = localStorage.getItem("user_id");
        this.indexlist = this.indexData - 1;
        this.dataRequest.popup_id = this.listPopUp.data[this.indexlist].id;
        this.CancelPopup(this.dataRequest);
      } else {
        this.dataRequest.shop_id = this.shop_id;
        this.dataRequest.user_id = localStorage.getItem("user_id");
        this.indexlist = this.indexData;
        this.dataRequest.popup_id = this.listPopUp.data[this.indexlist].id;
        this.CancelPopup(this.dataRequest);
        this.$emit("show_modal", this.isHideModal);
      }
    },
  },
};
</script>
<style scoped>
p {
  width: 50px;
  height: 100px;
}
</style>
