<template>
  <div class="list-menu-sp" :class="{ active: showMenu }" @click="closeMenu">
    <!-- <div class="list-menu-sp active"> -->
    <div class="backdrop" />
    <div class="sidebar-menu" style="overflow-y: auto">
      <div ref="sidebarScroll" class="sidebar-scroll pt-2">
        <div class="sidebar-items">
          <div
            class="sidebar-link"
            v-for="(item, index) in dataMenu1"
            :key="index"
          >
            <a :name="returnName(item)" :href="returnPage(item)">
              <div
                @click="clickItem()"
                class="sidebar-item position-relative pl-4"
                :class="{
                  'img-menu-header-sidebar': isImage(item.icon),
                }"
              >
                <img v-if="isImage(item.icon)" :src="item.icon" alt="" />
                <b-icon v-else :icon="item.icon" font-scale="1.5" />
                <span
                  class="ml-4"
                  v-if="item.page_title == '直接URLを入力する'"
                  >{{ item.title_other }}</span
                >
                <span class="ml-4" v-else>{{ item.page_title }}</span>
                <span
                  v-if="item.page_title == 'カート' && is_login === true"
                  class="sidebar-menu-item-cart position-absolute"
                >
                  {{ countListCart }}
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Routes } from "../../utils/routes";
import { Constants } from "../../utils/constants";
import vClickOutside from "v-click-outside";

export default {
  name: "Menu",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isActiveTabLibrary: false,
      beginUrl: this.$route.params.shopId
        ? "/" + Routes.SHOP + "/" + this.$route.params.shopId
        : "",
      type: 0,
      dataMenu1: [],
      countListCart: 0,
      is_login:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
      inquiry_URL: "",
    };
  },
  created() {
    if (this.is_login) {
      const shop_id = localStorage.getItem(Constants.SHOP_ID);
      // this.getListSettingPageHeader(localStorage.getItem(Constants.SHOP_ID));
      this.getListCart({
        shop_id,
      });
      // this.getListSettingShopUser({
      //   domain: Constants.DOMAIN,
      //   shop_id: shop_id,
      // });
    }
  },
  computed: {
    ...mapGetters([
      "listSettingPageHeader",
      "ListSettingShop",
      "showMenu",
      "listCart",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    listSettingPageHeader() {
      this.dataMenu1 = this.listSettingPageHeader;
    },
    listCart() {
      this.countListCart = this.listCart.length;
      // this.listCart.forEach((element) => {
      //   element.list_cart.forEach(() => {
      //     this.countListCart += 1;
      //   });
      // });
    },
    ListSettingShop() {
      if (this.ListSettingShop.length > 0) {
        this.inquiry_URL = this.ListSettingShop[0].url_qa;
      }
    },
  },
  methods: {
    ...mapActions({
      getListSettingPageHeader: "getListSettingPageHeader",
      getListCart: "getListCart",
      // getListSettingShopUser: "getListSettingShopUser",
    }),
    isImage(url) {
      return /\.(jpg|jpeg|png|svg|JPG|JPEG|PNG)$/.test(url);
    },
    clickItem() {
      this.$store.commit("set", ["showMenu", !this.showMenu]);
    },
    closeMenu(event) {
      if (event.target.parentElement.id == "menu") return;
      if (event.target.className == "sidebar-menu") return;
      this.$store.commit("set", ["showMenu", false]);
    },
    returnPage(item) {
      // if (item && item.type && item.type == 5) {
      //   return this.$router.resolve({
      //     name: this.$route.params.shopId ? "pageColumn" : "pageColumn domain",
      //     params: { id: item.fixed_page_id },
      //   }).href;
      // } else {
      if (item.page_title == "直接URLを入力する") {
        return item.url;
      }
      if (item.page_title == "お問い合わせ" && this.inquiry_URL) {
        return this.inquiry_URL;
      } else {
        return this.beginUrl + item.url;
      }
      // }
    },
    returnName(item) {
      if (item.fixed_page_id) {
        return "menu_fixed_" + item.fixed_page_id + "_link";
      } else {
        const name = item.url.replace("/", "_");
        return "menu" + name + "_link";
      }
    },
  },
};
</script>
