<template>
  <div class="footer" v-bind:class="{ BlockFooter: uiNote === 1 }">
    <div class="margin-content">
      <div class="container px-0">
        <b-row class="mx-0" v-if="isPayment">
          <b-col cols="12" md="12" class="pl-0">
            <ul class="policy-author footer-list list-unstyled">
              <li class="footer-item">
                <a target="blank" @click="setInfo()">特定商取引法上</a>
              </li>
              <li class="footer-item">
                <a @click="$bvModal.show('modal-policy')">プライバシー規約</a>
              </li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mx-0">
          <b-col cols="12" md="12" class="pl-0">
            <ul class="footer-list list-unstyled">
              <li
                class="footer-item"
                v-for="(item, index) in listFooter"
                :key="index"
              >
                <a
                  class="footer-link"
                  :href="item.url"
                  v-if="item.page_title == '直接URLを入力する'"
                >
                  {{ item.title_other }}
                </a>
                <!-- item.type=8が単発ページのページ選択のポップアップ 9は不明 @TODO 定数で管理する必要あり -->
                <a
                  class="footer-link"
                  v-else-if="item.type == 8 || item.type == 9"
                  v-on:click="showDetailFixed(item.fixed_page_id)"
                >
                  {{ item.page_title }}
                </a>
                <a
                  class="footer-link"
                  :href="getUrl(item.fixed_page_id)"
                  v-else
                >
                  {{ item.page_title }}
                </a>
                <!-- <router-link
                  :name="`footer_fixed_${item.fixed_page_id}_link`"
                  :to="{
                    name: $route.params.shopId
                      ? 'staticPage'
                      : 'staticPage domain',
                    params: { id: item.fixed_page_id },
                  }"
                  class="footer-link"
                >
                  {{ item.page_title }}
                </router-link> -->
              </li>
            </ul>
          </b-col>
          <div class="clearfix"></div>
          <b-col cols="12" class="pl-0">
            <div class="footer-copyright">
              Copyright © 2022 - All rights reserved.
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- modal policy -->
    <div>
      <b-modal
        id="modal-policy"
        hide-header
        hide-footer
        :size="'xl'"
        no-close-on-backdrop
      >
        <div class="d-flex justify-content-between">
          <h5>プライバシー規約</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('modal-policy')"
          >
            ×
          </button>
        </div>
        <hr />
        <div class="policy" v-html="policy"></div>
      </b-modal>
    </div>

    <!-- modal trade law -->
    <div>
      <b-modal id="modal-trade-law" hide-header hide-footer :size="'xl'">
        <div class="d-flex justify-content-between">
          <h5>特定商取引法上の表記</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('modal-trade-law')"
          >
            ×
          </button>
        </div>
        <hr />
        <CTextarea v-model="tradeLaw" rows="20" disabled></CTextarea>
      </b-modal>
    </div>

    <div>
      <PagePopup />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Routes } from "../../utils/routes";
import { Constants } from "../../utils/constants";
import PagePopup from "@/views/default/PagePopup";
import { eventBus } from "../../main";

export default {
  name: "Footer",
  components: {
    PagePopup,
  },
  data() {
    return {
      beginUrl: this.$route.params.shopId
        ? "/" + Routes.SHOP + "/" + this.$route.params.shopId
        : "",
      dataShop: [],
      logoShop: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      tradeLaw: "",
      policy: "",
      isPayment: false,
      type_column: Constants.FIXED_PAGE_TYPE["column_page"],
      uiNote: 0,
      is_login:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
    };
  },
  async created() {
    if (this.is_login) {
      if (this.$route.params.idPage && this.$route.params.idContent) {
        await this.getContentLibraryById({
          id: this.$route.params.idContent,
          shop_id: localStorage.getItem(Constants.SHOP_ID),
        });
      }
      const request = {
        domain: Constants.DOMAIN,
        shop_id: localStorage.getItem(Constants.SHOP_ID),
      };
      // await this.getListSettingShopUser(request);
      await this.getListFooter(request);
    }
  },
  computed: {
    ...mapGetters([
      "success",
      "error",
      "listFooter",
      "ListSettingShop",
      "detailStore",
      "contentLibraryById",
      "idModalFixed",
      "modalLoadingPage",
      // "isShowLayout",
    ]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
        }
      }
    },
    detailStore() {
      if (
        this.$route.fullPath.includes("payment") &&
        this.detailStore.length > 0
      ) {
        this.isPayment = true;
        this.tradeLaw = this.detailStore[0].url_trade_law;
        this.policy = this.detailStore[0].policy;
      }
    },
    $route(from) {
      if (!from.path.includes("payment")) {
        this.isPayment = false;
      }
    },
    contentLibraryById() {
      if (this.$route.params.idPage && this.$route.params.idContent) {
        if (this.contentLibraryById && this.contentLibraryById.length > 0) {
          this.uiNote = this.contentLibraryById[0].design_settings;
        }
      } else {
        this.uiNote = 0;
      }
    },
    idModalFixed() {
      if (this.idModalFixed && this.modalLoadingPage == false) {
        this.showDetailFixed(this.idModalFixed);
      }
    },
    // isShowLayout() {
    //   if (this.$route.fullPath.includes("fixed-page/")) {
    //     this.$store.commit("set", ["isShowLayout", true]);
    //   }
    // },
  },
  methods: {
    ...mapActions({
      // getListSettingShopUser: "getListSettingShopUser",
      getListFooter: "getListFooter",
      getContentLibraryById: "getContentLibraryById",
    }),
    setInfo() {
      let hostName = window.location.origin;
      let href = this.$route.params.shopId
        ? "/shop/" + this.$route.params.shopId + this.$route.fullPath
        : this.$route.fullPath;
      let urlCurrent = hostName + href;
      if (this.tradeLaw && this.tradeLaw != urlCurrent) {
        if (this.detailStore && this.detailStore.length > 0) {
          let totalPrice =
            this.detailStore[0] &&
            this.detailStore[0].product_price /
              (1 + this.detailStore[0].tax_rate * 0.01);
          let fee =
            this.detailStore[0] && this.detailStore[0].sale_basic.column[0].fee
              ? this.detailStore[0].sale_basic.column[0].fee
              : this.detailStore[0].consultation_fee_default;
          let tax =
            this.detailStore[0] &&
            this.detailStore[0].product_price - totalPrice;
          let totalPricePayment =
            this.detailStore[0] &&
            this.detailStore[0].product_price * 1 + fee * 1;
          let infoSubription =
            this.detailStore[0] && this.detailStore[0].sale_basic.column[0];
          let isSubscription =
            this.detailStore[0] &&
            this.detailStore[0].sale_basic.column[0].sale_format == 1
              ? true
              : false;
          const inforThanksPage = {
            before_purchase_title:
              this.detailStore[0] && this.detailStore[0].content_title,
            before_purchase_url: urlCurrent,
            before_purchase_price: Math.round(
              Number(totalPrice)
            ).toLocaleString("ja"),
            before_purchase_totalprice: isSubscription
              ? Math.round(Number(totalPricePayment)).toLocaleString("ja") +
                "円 /" +
                infoSubription.addBill +
                infoSubription.textChange
              : Math.round(Number(totalPricePayment)).toLocaleString("ja") +
                "円",
            before_purchase_postage: Math.round(Number(fee)).toLocaleString(
              "ja"
            ),
            before_purchase_tax: Math.round(Number(tax)).toLocaleString("ja"),
          };
          localStorage.setItem(
            Constants.INFOR_ORDER,
            JSON.stringify(inforThanksPage)
          );
        }
        window.open(this.tradeLaw, "_blank");
      }
    },
    getUrl: function (fixed_page_id) {
      if (this.$route.params.shopId) {
        return (
          location.protocol +
          "//" +
          location.hostname +
          (location.port ? ":" + location.port : "") +
          "/shop/" +
          this.$route.params.shopId +
          "/fixed-page/" +
          fixed_page_id
        );
      } else {
        return (
          location.protocol +
          "//" +
          location.hostname +
          (location.port ? ":" + location.port : "") +
          "/fixed-page/" +
          fixed_page_id
        );
      }
    },
    showDetailFixed(fixedId) {
      eventBus.$emit("loadModalFixed", fixedId);
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  padding: 20px 0 20px 0;
  position: relative;
  background-color: white;
  border-top: 2px solid #eee;
  bottom: 0px;
  min-height: 100px;
  &-logo {
    cursor: pointer;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-list {
    margin: 0;
  }
  &-item {
    display: inline-block;
    font-size: 13px;
    @media (max-width: 768px) {
      // text-align: center;
    }
  }
  &-item:not(:first-child) {
    margin: 0 0 0 12px;
    padding: 0 0 0 12px;
    border-left: 1px solid #8e8e8e;
  }
  &-link {
    color: #000;
    font-size: 14px;
    &:hover {
      text-decoration: none;
      color: #817f7f;
    }
  }
  &-copyright {
    margin: 15px 0 0;
    display: block;
    font-size: 12px;
    color: #817f7f;
  }
}
@media (max-width: 1024px) {
  .footer {
    text-align: center;
  }
}
.img-logo-footer {
  width: 286px;
  height: 79px;
}
@media (max-width: 992px) and (min-width: 768px) {
  .img-logo-footer {
    width: unset;
    height: unset;
  }
}
.policy-author {
  list-style: none;
  li {
    border-left: none !important;
    a {
      display: block;
      padding: 0 0 0 17px;
      font-size: 14px;
      background: url(../../assets/img/icon_purchase_arrow01.png) no-repeat left
        top 4px;
      text-decoration: none;
      color: #333;
    }
    a:hover {
      cursor: pointer;
    }
  }
}
.BlockFooter {
  display: none;
}
</style>
