<template>
  <div>
    <div class="default-layout">
      <Header />
      <Menu />
      <div class="content">
        <router-view :key="$route.fullPath"></router-view>
        <div class="fixedBtn" style="display: block">
          <a class="pagetop" href="#" style="display: block"
            ><img :src="btn_pagetop" alt=""
          /></a>
        </div>
      </div>
      <Footer />
      <NoteAndChatSP v-if="isShowButton === true" style="margin-bottom: 80px" />
    </div>
    <BottomMenu />
  </div>
</template>

<script>
import Footer from "./Footer";
import Header from "./HeaderTestLibrary";
import BottomMenu from "./BottomMenu";
import Menu from "./Menu";
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
import btn_pagetop from "@/assets/img/btn_pagetop.png";
import NoteAndChatSP from "../../views/default/NoteAndChatSP";

export default {
  name: "Layout",
  data() {
    return {
      btn_pagetop: btn_pagetop,
      shopId: this.$route.params.shopId
        ? this.$route.params.shopId
        : localStorage.getItem(Constants.SHOP_ID),
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
    };
  },
  components: {
    Header,
    Footer,
    BottomMenu,
    Menu,
    NoteAndChatSP,
  },
  methods: {
    ...mapActions({ getShopById: "getShopById" }),
    myFunction() {
      if (window.scrollY == 0) {
        document.getElementsByClassName("fixedBtn")[0].style.display = "none";
      } else {
        document.getElementsByClassName("fixedBtn")[0].style.display = "block";
      }
    },
  },
  created() {
    if (this.$route.fullPath.includes("content"))
      this.$store.commit("set", ["type", 2]);
    this.getShopById(this.shopId);
    window.addEventListener("scroll", this.myFunction);
  },
  computed: {
    ...mapGetters(["type", "isShowButton"]),
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "../../views/default/Default.scss";
@import "../../views/default/Responsive.scss";
.fixedBtn {
  position: fixed;
  bottom: 70px;
  right: 10px;
  left: 85%;
  width: 40px;
  height: 90px;
  max-width: 100%;
  margin: auto;
  z-index: 1000;
  display: none;
}
.fixedBtn a {
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  float: right;
  clear: both;
}
.fixedBtn img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>
